@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";

html {
    height: 100%;
}
body {
    height: 100%;
    padding-left: 81px;

    .main-container, .print-container {
        font-family: @dax;
        color: @gray-darker;
        line-height: 1.2;
        height: 100%;
    }
}

h1 {
    margin: 0 0 20px 0;
}
h2 {
    margin-top: 0;
}

h3 {
    font-family: @dax;
    font-size: 22px;
    font-weight: bold;
}

p {
    margin: 0;
}

input[type="file"], .help-block {
    display: inline-block;
}

ul {
    list-style-type: square;
    padding-left: 16px;
}
ol {
    padding-left: 20px;
}

.capitalize {
    font-size: 18px;
    font-family: @roboto;
    text-transform: uppercase;
}

.title-section {
    display: inline-block;
    border-top: 2px solid @gray-darker;
    font-family: Roboto, Helvetica;
    font-size: 28px;
    padding-top: 5px;
    text-transform: uppercase;
    margin-bottom: 20px;
    max-width: 810px;
    width: 100%;

    p {
        font-size: 28px;
        font-weight: bold;
    }

}

.btn-flat {
    display: inline-block;
    border: 0;
    border-radius: 0;
    color: #fff;
    padding: 8px 12px 7px 12px;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    background-color: @green;

    &:hover {
        background-color: darken(@green, 2%);
    }
    &:focus {
        outline: 0;
    }
    &:active {
        .box-shadow(inset 0 3px 5px rgba(0,0,0,.075));
    }

    img {
        height: 24px;
        margin-top: -4px;
        margin-left: 5px;
        margin-right: -10px;
        vertical-align: middle;
    }
}
.btn-gray {
    background-color: @gray-lighter;

    &:hover {
        background-color: darken(@gray-lighter, 3%);
    }
}

.btn-tick {
    position: relative;
    padding: 0;
    width: 20px;
    height: 20px;
    border: 1px solid @gray-light;
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }
    &:active {
        .box-shadow(none);
    }

    img {
        display: none;
        position: absolute;
        height: 17px;
        margin: 0;
        right: -4px;
        top: -2px;
    }

    &.btn-tick-on {
        img {
            display: block;
        }
    }
}
.btn-small {
    padding: 6px 8px 4px 8px;
    font-size: 14px;
    line-height: 14px;
    margin-top: 1px;
}
.btn-large {
    font-size: 18px;
    line-height: 40px;
    padding: 4px 20px 0 20px;
}
.btn-note {
    background-color: #fff;
    border: 2px solid @gray-lighter;
    color: @gray-light;
    padding: 5px 8px 3px 8px;

    &:hover {
        background-color: #fff;
        border-color: darken(@gray-lighter, 15%);
        color: darken(@gray-light, 15%);
    }
}

.ui-helper-hidden-accessible {
    display: none;
}
ul.ui-autocomplete {
    background-color: #eee;
    list-style: none;
    width: 480px;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    border-radius: 0 0 3px 3px;
    margin: 0;
    padding: 0;

    li {
        height: 25px;
        line-height: 25px;
    }

    .ui-menu-item a {
        height: 25px;
        display: block;
        padding-left: 12px;

        &.ui-state-focus, &.ui-state-active {
            background-color: @brand-primary;
            color: #fff;
        }
    }
}

.menu-items-order-help {
    float: left;
    margin-left: 20px;
}

.menu-items-order {
    width: 181px;
    background: #2e2e2e;
    padding-left: 0;
    position: relative;
    list-style-type: none;
    float: left;
}

.menu-items-order li {
    width: 100%;
    border-bottom: 2px solid #2e2e2e;
    background: #3c3c3d;
    height: 50px;
    position: relative;
    cursor: move;
}

.menu-items-order li:before {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    background-color: #ccc;
    content: "\f0c9";
    font-family: FontAwesome;
    text-align: center;
    line-height: 50px;
    border-left: 2px solid #000;
}

.menu-items-order li:first-child:before {
    border-top: 2px solid #000;
}

.menu-items-order li span.link {
    padding-top: 20px;
    font-size: 12px;
    color: #fff;
    display: block;
    vertical-align: middle;
    text-transform: uppercase;
    padding-left: 30px;
}
